import axios from "axios";
import { useState, useEffect } from "react";
import TypeChambreChip from "./TypeChambreChip";
import { useNavigate } from "react-router-dom";
import { get } from "../Utility/Requester";

export default function ListeChambre() {

  const [types, setTypes] = useState([]);

  const navigate = useNavigate();

  const getAllTypes = async () => {
    get("/chambres/types/all").then(
      data => {
        setTypes(data);
      },
      
    ).catch(err => alert(err));
  };

  const onTypeClick = (type) => {
    navigate(`/residence/${type}`);
  }

  useEffect(() => {
    getAllTypes();
  }, []);

  return (
    
      <div className="min-h-max w-10/12 mt-10 bg-white p-10 rounded-t-xl">
        <h1 className=" mb-10 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-[#B11217] text-center">
          Types de chambres
        </h1>
        <div className="flex flex-col gap-y-5">
          {types.map((t) => {
            return (
              <TypeChambreChip
                type={t.type}
                description={t.description_base}
                prix={t.prix_base}
                onClick={() => onTypeClick(t.type)}
              ></TypeChambreChip>
            );
          })}

          <div className="mt-4">
            <legend className="text-[#B11217] float-left">
              * Veuillez noter que les prix affichés sont sujets à modification.
            </legend>
            <br/>
            <h2 className="font-bold">Les services ci-dessous sont inclus dans les prix de base :</h2>
            <ul className="list-disc ml-8">
              <li>Loyer de base</li>
              <li>3 repas et 2 collations à la salle à manger</li>
              <li>Entretien des vêtements (1/sem.)</li>
              <li>Entretien de la literie (1/mois)</li>
              <li>Léger entretien ménagé (2/mois)</li>
              <li>Internet à basse vitesse</li>
              <li>Câblodistribution selon le forfait de la RPA</li>
            </ul>
            <br/>
            <h2 className="font-bold">Les services ci-dessous ne sont <u>PAS</u> inclus dans les prix de base :</h2>
            <ul className="list-disc ml-8">
              <li>Services d'assistance personnelle</li>
            </ul>
          </div>
          
        </div>
      </div>
  );
}
