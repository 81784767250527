import * as React from "react";
import Dashboard from "../../../Dashboard";
import { useAuth } from "../../../../AuthProvider";
import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultSortableTable from "./DefaultSortableTable";
import { del, get } from "../../../../Utility/Requester";

export default function Residents() {
  const { user, setAlert, setSeverity } = useAuth();
  const [rows, setRows] = useState([]);

  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const headCells = [
    {
      id: "prenom",
      label: "Prénom",
    },
    {
      id: "nom",
      label: "Nom",
    },
    {
      id: "date_residence",
      label: "Date de résidence",
    },

    {
      id: "telephone_urgence",
      label: "Téléphone d'urgence",
    },
    {
      id: "numero",
      label: "Numéro de chambre",
    },
  ];

  const deleteRow = (id) => {
    del("residents/delete", { data: { id: id } })
      .then((data) => {
        setSeverity("success");
        setAlert(data.message);
        //fetchResidents();
        setRows((oldRows) => oldRows.filter((r) => r.id !== id));
      })
      .catch(function (error) {
        console.log(error);
        setSeverity("error");
        setAlert(error.message);
      });
  };

  const fetchResidents = () => {
    get("/residents/all")
      .then(function (data) {
        data.map((r) => {
          r.date_residence = formatDate(r.date_residence);
        });
        setRows(data);
      })
      .catch(function (error) {
        console.log(error);
        setSeverity("error");
        setAlert(error);
      });
  };

  useEffect(() => {
    fetchResidents();
  }, []);

  return (
    <Dashboard>
      <div className="w-full mb-3 ">
        <h1 className="flex-none float-left text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600">
          Résidents
        </h1>
        <Button
          className="float-right p-2 w-28 bg-red-600"
          onClick={() => {
            navigate("/dashboard/residents/add");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Ajouter
        </Button>
      </div>
      <DefaultSortableTable
        confirmMessageParam={["prenom", "nom"]}
        redirectOnEdit
        onEditRow={(id) => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          navigate("/dashboard/residents/" + id + "/edit");
        }}
        editable={user.role === "Administrateur"}
        deleteable={user.role === "Administrateur"}
        onDeleteRow={deleteRow}
        rows={rows}
        headCells={headCells}
        onRowNavigation={"/dashboard/residents/"}
        rowKeyParam="id"
        rowNavigationParam="id"
      />
    </Dashboard>
  );
}
