import * as React from "react";
import Dashboard from "../../../Dashboard";
import { useAuth } from "../../../../AuthProvider";
import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultSortableTable from "./DefaultSortableTable";
import { del, get, post } from "../../../../Utility/Requester";

export default function Employes() {
	const { user, setAlert, setSeverity } = useAuth();
	const [rows, setRows] = useState([]);

	const navigate = useNavigate();
	const [headCells, setHeadCells] = useState([
		{
			id: "prenom",
			label: "Prénom",
			type: "text",
            editable: true,
		},
		{
			id: "nom",
			label: "Nom",
			type: "text",
            editable: true,
		},
		{
			id: "email",
			label: "Email",
			type: "text",
            editable: false,
		},
		{
			id: "poste",
			label: "Poste",
			type: [],
            editable: true,
		},
		{
			id: "genre",
			label: "Genre",
			type: ["M", null,"F"],
            editable: true,
		},
	]);



	const fetchPostes = () => 
		get("postes/all")
			.then((data) => {
				headCells[3].type = (data.map((p) => p.nom_masculin));
				setHeadCells(headCells);
			})
			.catch(function (error) {
				console.log(error);
				setSeverity("error");
				setAlert(error);
			});

	const deleteRow = (id) => {
		del("employes/delete", {data: {"id": id}})
         .then(
			data => {
              setAlert(data.message);
              setSeverity("success");
			  //fetchEmployes();
              setRows(oldRows => oldRows.filter(r => r.id !== id)); // Would make stuff cost less
            }
         )
         .catch(function(error){
            console.log(error);
			setAlert(error.message);
            setSeverity("error");
         })
	}

	const fetchEmployes = () => {
		get("/employes/all")
		.then(function (data) {
			setRows(data);
		})
		.catch(function (error) {
			console.log(error);
			setSeverity("error");
			setAlert(error);
		});
	}

	const onEdit = (edited) => {
		post("/employes/edit", {
			email: edited.email,
			prenom: edited.prenom,
			nom: edited.nom,
			poste: edited.poste,
			genre: edited.genre || null,
		}).then(
			data => {
				fetchEmployes();
				setSeverity("success");
				setAlert(data.message);
			}
		).catch((err) => {
			setSeverity("error");
			setAlert(err.message);
			console.log("Erreur lors de la modification d'employé : " + err);
		})
	}

	useEffect(() => {
		fetchEmployes();
		fetchPostes();
	}, []);

	return (
		<Dashboard>
			<div className="w-full mb-3">
				<h1 className="flex-none float-left text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600">
					Employés
				</h1>
				<Button
					className="float-right p-2 w-28 bg-red-600"
					onClick={() => navigate("/dashboard/employes/add")}
				>
					Ajouter
				</Button>
			</div>
			<DefaultSortableTable 
				headCells={headCells}
				rows={rows} 
				onRowNavigation={"/dashboard/employes/"} 
				rowNavigationParam="id"
				rowKeyParam={"id"}
				editable={true}
				onEditRow={onEdit}
				deleteable={user.role === "Administrateur"}
				onDeleteRow={deleteRow}
				confirmMessageParam={["prenom", "nom"]}
			/>
		</Dashboard >

	);
}
