import "react-phone-number-input/style.css";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthProvider";
import Dashboard from "../../../Dashboard";
import { Button } from "@material-tailwind/react";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/joy/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { get, post } from "../../../../Utility/Requester";
import { useNavigate } from "react-router-dom";

export default function EmployeAjout() {
  const { user, setAlert, setSeverity } = useAuth();

  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [password, setPassword] = useState("");
  const [poste, setPoste] = useState("");
  const [postes, setPostes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    get("postes/all")
      .then(function (data) {
        setPostes(data);
      })
      .catch(function (error) {
        console.log(error);
        setSeverity("error");
        setAlert(error);
      });
  }, []);

  const listItems = postes.map((c) => (
    <MenuItem key={c.id} value={c.id}>
      {c.nom_masculin}
    </MenuItem>
  ));

  const sumbmitAjout = () => {
    if (
      prenom == null ||
      prenom.trim() == "" ||
      nom == null ||
      nom.trim() == "" ||
      password.trim() == "" ||
      password.trim() == null
    ) {
      setAlert("Veuillez entrer tout les champs obligatoires");
      setSeverity("error");
    } else {
      post("/employes/add", {
        prenom: prenom,
        nom: nom,
        poste: poste,
        mdp: password,
      })
        .then((data) => {
          setAlert(data.message);
          setSeverity("success");
          navigate("/dashboard/employes");
        })
        .catch(function (error) {
          console.log(error.message);
          setAlert(error.message);
          setSeverity("error");
        });
    }
  };

  return (
    !user && (
      <Dashboard>
        <div className="w-full">
          <div className="mb-10 flex items-center justify-between flex-wrap gap-5 px-4">
            <h1 className="text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600">
              Ajouter un employé
            </h1>
          </div>

          <div className="grid gap-5 px-4 ">
            <FormControl>
              <div>
                Prénom <span className="text-red-600">*</span>
              </div>
              <Input
                className="w-full xl:w-2/3 2xl:w-1/2"
                value={prenom}
                onChange={(newValue) => setPrenom(newValue.target.value)}
              />
              <br />

              <div>
                Nom <span className="text-red-600">*</span>
              </div>
              <Input
                className="w-full xl:w-2/3 2xl:w-1/2"
                value={nom}
                onChange={(newValue) => setNom(newValue.target.value)}
              />
              <br />

              <div>
                Mot de passe <span className="text-red-600">*</span>
              </div>
              <Input
                className="w-full xl:w-2/3 2xl:w-1/2"
                value={password}
                type="password"
                onChange={(newValue) => setPassword(newValue.target.value)}
              />
              <br />

              <div className="mt-3 w-full md:w-1/2">
                <div className="w-full sm:w-1/2 md:w-full xl:w-2/3 2xl:w-1/2">
                  <div>Poste</div>
                  <Select
                    className="w-full bg-[#FBFCFE] border-[#CDD7E1] h-11"
                    value={poste}
                    onChange={(newValue) => setPoste(newValue.target.value)}
                  >
                    <MenuItem value=""> </MenuItem>
                    {listItems}
                  </Select>
                </div>
              </div>
            </FormControl>

            <legend>
              <span className="text-red-600">*</span> Obligatoire
            </legend>
            <Button
              className="bg-red-600 px-2 w-52 mt-5"
              onClick={() => sumbmitAjout()}
            >
              Soumettre
            </Button>
          </div>
        </div>
      </Dashboard>
    )
  );
}
