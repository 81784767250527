import { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthProvider";
import Dashboard from "../../../Dashboard";
import DefaultSortableTable from "./DefaultSortableTable";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { del, get, post } from "../../../../Utility/Requester";

export default function Postes() {
    const navigate = useNavigate("");

    const { user, setAlert, setSeverity } = useAuth();
    const [rows, setRows] = useState([]);

    const deleteRow = (id) => {
        del("postes/delete", {data: {"id": id}})
             .then(
                data => {
                  setSeverity("success");
                  setAlert(data.message);
                  //fetchPostes();
                  setRows(oldRows => oldRows.filter(r => r.id !== id));
                }
            ).catch(function(error){
                console.log(error);
                setSeverity("error");
                setAlert(error.message);
            })
      }

    const headCells = [
        {
            id: "nom_masculin",
            label: "Nom Masculin",
            type: "text",
            editable: true,
        },
        {
            id: "nom_feminin",
            label: "Nom Féminin",
            type: "text",
            editable: true,
        },
        {
            id: "role",
            label: "Rôle",
            type: ["Administrateur", "Soin", "Base"],
            editable: true,
        },
    ];


    const fetchPostes = () => {
        get("/postes/all")
        .then((data) => {
            setRows(data);
        }).catch((error) => {
            console.log("Erreur lors du fetch des postes : " + error);
            setSeverity("error");
            setAlert(error);
        })
    }

    const onEdit = (edited) => {
		post("/postes/edit", {
			id: edited.id,
            nomMasculin: edited.nom_masculin,
            nomFeminin: edited.nom_feminin,
            role: edited.role,
		}).then(
            data => {
				fetchPostes();
				setSeverity("success");
		        setAlert(data.message);
		    }
        ).catch((err) => {
			setSeverity("error");
			setAlert(err.message);
			console.log("Erreur lors de la modification de poste : " + err);
		})
	}

    useEffect(() => {
       fetchPostes(); 
    },[])

    return (
        user && user.role === "Administrateur" &&
        <Dashboard>
			<div className="w-full mb-3">
				<h1 className="flex-none float-left text-2xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl text-red-600">
					Postes
				</h1>
                <Button
					className="float-right p-2 w-28 bg-red-600"
					onClick={() => navigate("/dashboard/postes/add")}
				>
					Ajouter
				</Button>
			</div>
            <DefaultSortableTable
                confirmMessageParam={["nom_masculin"]}
                rows={rows}
                headCells={headCells}
                deleteable = {user.role === "Administrateur"}
				onDeleteRow = {deleteRow}
                rowKeyParam="id"
                editable={user.role === "Administrateur"}
                onEditRow={onEdit}
            />
        </Dashboard>
    );

}