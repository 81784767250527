import {
  Button,
  Divider,
  Pagination,
  PaginationItem,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState } from "react";
import Commentaire from "./Commentaire";
import { useAuth } from "../../../../AuthProvider";
import CommentaireAjout from "./CommentaireAjout";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { get } from "../../../../Utility/Requester";

export default function SectionCommentaires({ residentId }) {
  const { user, setAlert, setSeverity } = useAuth();
  const [commentaires, setCommentaires] = useState([]);
  const nombreCommentaires = 5;
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [creating, setCreating] = useState(false);

  const fetchCommentaires = async () => {
    let url = `/commentaireResidents/forResident/${residentId}`;
    get(url).then(
      data => {
        setCommentaires(Array.from(data));
        setMaxPage(Math.ceil(data.length / nombreCommentaires));
      }
    ).catch(err => {
      setSeverity("error");
      setAlert(err);
      console.log(err);
    })
  };

  const removeComment = (id) => {
    setCommentaires(comments => comments.filter(c => c.id !== id));
  }

  useEffect(() => {
    fetchCommentaires();
  },[setCommentaires, setPage, setMaxPage]);

  return commentaires && commentaires.length > 0 ? (
    <div>
      <div className="flex justify-between mb-6">
        <h1 className="text-[#B11217] text-3xl font-bold">Commentaires</h1>
        {(user.role === "Soin" || user.role === "Administrateur") && (
          <div className="self-end">
            <div className="hidden sm:block">
              <Button
                onClick={() => setCreating(true)}
                color="error"
                variant="contained"
              >
                Ajouter un commentaire
              </Button>
            </div>
            <div className="sm:hidden">
              <AddCommentIcon
                color="error"
                fontSize="large"
                onClick={() => setCreating(true)}
              />
            </div>
            <CommentaireAjout
              residentId={residentId}
              showing={creating}
              close={() => {
                setCreating(false);
                fetchCommentaires();
              }}
            />
          </div>
        )}
      </div>
      {maxPage > 1 && (
        <Pagination
          className="flex justify-end"
          count={maxPage}
          page={page}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
          onChange={(event, value) => setPage(value)}
        />
      )}
      <Stack
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={2}
      >
        {commentaires
          .slice(
            (page - 1) * nombreCommentaires,
            (page - 1) * nombreCommentaires + nombreCommentaires
          )
          .map((c) => 
              <Commentaire
                key={c.id}
                id={c.id}
                date={c.date}
                commentaire={c.texte}
                auteur={c.employe}
                auteurId={c.employe_id}
                onReload={() => removeComment(c.id)}
              />
          )}
      </Stack>
      {maxPage > 1 && (
        <Pagination
          className="flex justify-end"
          count={maxPage}
          page={page}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
          onChange={(event, value) => setPage(value)}
        />
      )}
    </div>
  ) : (
    (user.role === "Soin" || user.role === "Administrateur") && (
      <div className="flex justify-end">
        <div className="hidden sm:block">
          <Button
            onClick={() => setCreating(true)}
            color="error"
            variant="contained"
          >
            Ajouter un commentaire
          </Button>
        </div>
        <div className="sm:hidden">
          <AddCommentIcon
            color="error"
            fontSize="large"
            onClick={() => setCreating(true)}
          />
        </div>
        <CommentaireAjout
          residentId={residentId}
          showing={creating}
          close={() => {
            setCreating(false);
            fetchCommentaires();
          }}
        />
      </div>
    )
  );
}
