import { Avatar, Typography, Button } from "@material-tailwind/react";
import { ProfileInfoCard } from "../../widgets/cards/index";
import Dashboard from "../../../Dashboard";
import { useAuth } from "../../../../AuthProvider";
import { useRef, useState } from "react";
import Drawer from "@mui/material/Drawer";
import ChangementMotPasse from "./ChangementMotPasse";
import axios from "axios";

export default function Profile() {
  const { user, avatarUrl, setAlert, setSeverity } = useAuth();
  const [open, setOpen] = useState(false);
  const fileInput = useRef(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const handlePfpChange = (event) => {
    let file = event.target.files[0];
    const formData = new FormData();
    formData.set("image", file);
    axios
      .post("/utilisateurs/changeAvatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setTimeout(() => document.location.reload(), 4000);
          response.data.message +=
            "\nLa page va être actualisée dans quelques secondes.";
          setSeverity("success");
        } else {
          setSeverity("error");
        }
        setAlert(response.data.message);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("error");
        setAlert(err);
      });
  };

  return (
    <Dashboard>
      <div>
        <div className="mb-10 flex items-center justify-between flex-wrap gap-6">
          <div className="flex items-center gap-6">
            <input
              ref={fileInput}
              type="file"
              className="hidden"
              onChange={handlePfpChange}
            />
            <Avatar
              src={avatarUrl}
              alt={"employe"}
              size="xl"
              variant="rounded"
              className="rounded-lg cursor-pointer shadow-lg shadow-blue-gray-500/40"
              onClick={() => fileInput.current.click()}
              title="Modifier la photo de profile"
            />
            <div>
              <Typography variant="h5" color="blue-gray" className="mb-1">
                {user.prenom} {user.nom}
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-blue-gray-600"
              >
                {user.type}
              </Typography>
            </div>
          </div>
        </div>
        <div className="grid gap-5 px-4 w-min">
          {user.type === "Employé" ? (
            <ProfileInfoCard
              title="Information du profil"
              details={{
                Email: user.email,
                Poste: user.poste,
                Genre: user.genre,
              }}
            />
          ) : (
            <ProfileInfoCard
              title="Information du profil"
              details={{
                Email: user.email,
              }}
            />
          )}
          <Button
            className="bg-red-600 p-2 w-full"
            onClick={toggleDrawer(true)}
          >
            Modifier votre mot de passe
          </Button>
          <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
            <ChangementMotPasse
              anchor="right"
              toggleDrawer={setOpen}
            ></ChangementMotPasse>
          </Drawer>
        </div>
      </div>
    </Dashboard>
  );
}
